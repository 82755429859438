import React from 'react'

import TermsPages from '../partials/Terms/About'
import { dataPaymentLinkTerms } from '../data/terms/terms-payment-link'

const PaymentLinkTermsConditionsPage = (): JSX.Element => (
  <TermsPages
    data={dataPaymentLinkTerms}
    linkCanonical="termos-e-condicoes-do-link-de-pagamento"
  />
)

export default PaymentLinkTermsConditionsPage
