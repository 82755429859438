import { TermsProps } from '../../@interface/terms'

export const dataPaymentLinkTerms: TermsProps = {
 title: 'Termos e condições do Link de Pagamento',
  subtitle: 'de Link de Pagamento',
  update: '27 de Maio de 2024',
  about: {
    title: 'Termos e condições do Link de Pagamento',
    introduction: `Este documento (“Termos de Uso”) apresenta as condições de uso dos serviços
    relacionados ao Link de Pagamentos Cora (“Link de Pagamentos Cora” ou
    “Serviços”) prestados pela CORA TECNOLOGIA LTDA, sociedade com sede na
    Rua Frei Caneca, 1246, 3º andar, sala 31, Consolação, no Município de São Paulo,
    Estado de São Paulo, inscrita no Cadastro Nacional da Pessoa Jurídica do
    Ministério da Economia (“CNPJ/ME”) sob o no 34.052.649/0001-78 (“CORA
    TECH”), que desenvolve atividades de tecnologia e integra o mesmo grupo
    econômico que a Cora Sociedade de Crédito, Financiamento e Investimento S.A., sociedade com
    sede na Rua Frei Caneca, 1246, 3º andar, sala 31, Consolação, no Município de
    São Paulo, Estado de São Paulo, inscrita no Cadastro Nacional da Pessoa Jurídica
    do Ministério da Economia (“CNPJ/ME”) sob o nº 37.880.206/0001-63 (“CORA
    SCFI” e quando usado em conjunto com a CORA TECH apenas “CORA” ou “Nós”),
    na qual Sua Empresa mantém sua Conta Cora.</br>
    </br>
    Ao utilizar-se dos Serviços oferecidos pela CORA TECH, Sua Empresa,
    representada por Você, celebra um contrato conosco e assume os direitos e
    obrigações que apresentamos ao longo destes Termos de Uso. </br>
    </br>
    Você deve ler atentamente estes Termos de Uso, por inteiro, pois a utilização dos
    Serviços implica sua total concordância com as disposições deste documento.</br>
    </br>
    Caso não concorde com qualquer disposição deste instrumento, não utilize os Serviços.
      `,
  },
  section: [
    {
      list: [
        {
          head: 'Definições Importantes',
          items: [
            {
              item: 'O presente Termo e os direitos e obrigações aqui contidos são complementares aos Termos e Condições Gerais de Uso (“Termos de Uso da Conta Cora”) e a Política de Privacidade, que se aplicam a todos os titulares de Conta Cora e devem ser interpretados em conjunto.'
            },
            {
              item: 'As definições aqui apresentadas foram retiradas dos Termos de Uso da Conta Cora e servem para o melhor entendimento dos termos utilizados para o Plano Pago da Conta Cora, quaisquer termos em letras maiúsculas que não sejam definidos aqui terão suas definições estabelecidas em nossos Termos de Uso.'
            },
            {
              item: 'Nestes Termos de Uso, os termos abaixo iniciados em letras maiúsculas deverão ter o seguinte significado:',
              child: [
                {
                  item: '<strong>Conta Cora:</strong> é a conta de pagamento digital pré-paga destinada à realização de transações de pagamento.'
                },
                {
                  item: '<strong>Sua  Empresa:</strong>  pessoa  jurídica  constituída  no  Brasil,  devidamente  inscrita  na Receita  Federal  do  Brasil,  titular  da  Conta  Cora,  cujos  representantes  são autorizados a acessar as Aplicações e/ou utilizar os Serviços.'
                },
                {
                  item: '<strong>Usuário:</strong>  pessoa  física  representante  de  Sua  Empresa,  com  poderes  atribuídos pelos documentos societários de Sua Empresa e/oou procuração.'
                },
                {
                  item: '<strong>Você:</strong>  Usuário  responsável  pelo  cadastro  de  Sua  Empresa  para  acesso  aos Serviços.'
                },
              ]
            },
          ],
        },
        {
          head: 'O Link de Pagamentos',
          items: [
            {
              item: `O Link de Pagamentos Cora é uma funcionalidade por meio da qual Sua Empresa poderá impulsionar sua atividade, oferecendo a seus clientes a
              possibilidade de realizarem pagamentos por transações decorrentes da comercialização de produtos e/ou prestação de serviços através de cartões de crédito,
              mesmo que Sua Empresa não disponha de uma maquininha (terminal POS) (“Transações”, no plural, e “Transação”, no singular).`
            },
            {
              item: `Para oferecê-lo à Sua Empresa, a CORA TECH customizou uma solução de pagamentos, por meio do processamento de dados e de integrações da
              plataforma da CORA com o sistema da ZOOP TECNOLOGIA & INSTITUIÇÃO DE PAGAMENTO S.A., sociedade anônima fechada, inscrita no CNPJ sob o nº
              19.468.242/0001-32, com sede na Avenida das Américas, n.º 700, Bloco 5, salas 101 a 104 e 301 a 316, bairro Barra da Tijuca, cidade do Rio de Janeiro, estado
              do Rio de Janeiro, CEP 22640-100, um parceiro de negócio da CORA TECH que atua como subcredenciador, prestando serviços de tecnologia relacionada a
              pagamento por meios eletrônicos, mediante captura, transmissão, processamento e liquidação de transações com cartão (Parceiro).`
            },
            {
              item: `Os recebíveis originados das Transações realizadas através do Link de Pagamentos poderão ser liquidados pelo Parceiro para Sua Empresa de forma
              antecipada em relação à data originariamente prevista nas regras do arranjo de pagamento do qual o Parceiro faz parte, hipótese na qual Sua Empresa
              contratará, diretamente com o Parceiro e sem qualquer participação da CORA, uma operação de antecipação, cuja taxa incidente sobre o valor bruto dos
              recebíveis (Taxa de Antecipação) representará fator adicional de redução no valor líquido a ser liquidado pelo Parceiro na sua Conta Cora em contrapartida à
              disponibilização do recurso de forma antecipada à Sua Empresa.4. Ao utilizar os Serviços, Sua Empresa, representada por Você:`,
              child: [
                {
                  item: 'declara que possui CNPJ ativo e válido, que não atua em segmento ilegal e que não desenvolve atividade contrária à legislação vigente;'
                },
                {
                  item: 'autoriza a CORA a compartilhar seus dados com o Parceiro, para viabilizar seu credenciamento ao sistema do Parceiro;'
                },
                {
                  item: 'adere automaticamente às condições de uso do sistema do Parceiro, disponível em disponível em: https://docs.zoop.co/page/contrato-de-prestação-conjunta-de-serviços e reconhece que o Parceiro poderá, a qualquer momento, alterar as cláusulas ali estipuladas para promover adequações necessárias decorrentes de quaisquer normas regulatórias;'
                },
                {
                  item: 'declara ciência de que as Transações realizadas através do Link de Pagamentos terão o valor mínimo e máximo indicados na respectiva tela do aplicativo, bem como que, na hipótese de parcelamento do pagamento, o número máximo de parcelas será aquele indicado na respectiva tela do aplicativo na qual Você poderá imputá-lo;'
                },
                {
                  item: 'declara ciência de que a liquidação das Transações realizadas através do Link de Pagamentos será feita na sua Conta Cora pelo Parceiro, observadas as regras do arranjo de pagamento do qual o Parceiro faz parte;'
                },
                {
                  item: 'concorda que o valor líquido a ser creditado pelo Parceiro na sua Conta Cora decorrente do valor de cada Transação realizada utilizando o Link de Pagamentos será aquele correspondente ao valor bruto da Transação deduzido o importe equivalente ao percentual de cada Transação, a ser informado na tela do aplicativo no momento do envio de cada Link de Pagamentos e sujeito a alterações e reajustes de tempos em tempos, relativo (i) à contraprestação devida à CORA TECH, ao Parceiro e à Adquirência ao qual o Parceiro estiver vinculado dentro do arranjo de pagamento do qual o Parceiro faz parte e (ii) à Taxa de Antecipação, se aplicável;'
                },
                {
                  item: 'assume total responsabilidade pelo valor da Transação, possibilidade de parcelamento, número de parcelas, tempo de disponibilidade e dados do destinatário a serem imputados por Você no Link de Pagamentos;'
                },
                {
                  item: 'autoriza expressamente o Parceiro a compartilhar com a CORA os dados relacionados às suas Transações realizadas com o Link de Pagamentos;'
                },
                {
                  item: 'compromete-se a, sempre que solicitado, disponibilizar à CORA e/ou ao Parceiro todas as informações e documentos necessários para o procedimento de chargeback / disputas das Transações contestadas pelos seus clientes'
                },
                {
                  item: `no caso de Transações já liquidadas à Sua Empresa pelo Parceiro que venham a ser contestadas pelo seu cliente e resultem em estorno do valor em favor do
                  seu cliente, (a) autoriza a CORA, em caráter irrevogável e irretratável, a debitar da sua Conta Cora o valor total relativo à(s) Transação(ões) estornada(s) já
                  liquidada(s); (b) autoriza o Parceiro a compensar o valor estornado de recebíveis futuros integrantes da sua agenda ainda a serem liquidados pelo Parceiro,
                  reconhecendo que a CORA ficará automaticamente sub-rogada no direito de receber tais recebíveis futuros até que seja integralmente ressarcida pela(s)
                  Transação(ões) estornada(s) já liquidada(s); (c) reconhece que a CORA poderá emitir boleto(s) de cobrança em face da Sua Empresa para ser ressarcida pela(s)
                  Transação(ões) estornada(s) já liquidada(s); e (d) reconhece que o não ressarcimento da CORA por qualquer dos meios listados nos itens (a), (b) e (c)
                  acima sujeitará Sua Empresa, automaticamente e independentemente de qualquer notificação prévia, à incidência de juros moratórios de 1% (um por
                  cento) ao mês, multa de 2% (dois por cento) sobre o valor em aberto e quaisquer tributos/impostos incidentes, bem como à eventual apontamento de restrição
                  creditícia do nome de Sua Empresa junto aos órgãos de proteção ao crédito, se aplicável;`
                },
                {
                  item: 'reconhece que a CORA não responde, sob hipótese alguma, pela ausência ou pelo atraso na liquidação das Transações pelo Parceiro, por quaisquer danos morais, materiais, diretos ou indiretos, lucros cessantes ou perdas de receita decorrentes de qualquer falha, interrupção, mau funcionamento, atraso ou lentidão no sistema do Parceiro;'
                },
                {
                  item: 'assume a responsabilidade de manter a CORA indene perante o Parceiro, seu cliente e quaisquer terceiros em quaisquer hipóteses relacionadas às Transações por Sua Empresa realizadas através do Link de Pagamentos, inclusive, mas não limitada a, nos casos de cancelamento, chargeback ou outras hipóteses de estornos da Transação, bem como em eventuais ações judiciais ou processos administrativos de qualquer natureza; e'
                },
                {
                  item: `compromete-se a não utilizar os Serviços para a realização de pagamentos, transferências ou cobranças relacionadas: (a) a atividades ilegais, tais como,
                  lavagem de dinheiro, estelionato, realização de fraudes contra terceiros, jogos de azar e apostas em geral; (b) à compra e venda de produtos e serviços
                  considerados ilegais pela legislação brasileira; (c) à venda de produtos ou serviços identificados pelos órgãos governamentais como tendo uma alta
                  probabilidade de serem fraudulentos ou que representem risco à segurança física e financeira do consumidor; (d) a produtos que incentivem, promovam, facilitem
                  ou ensinem pessoas a praticarem atividades ilegais; e (e) à promoção de ódio, violência, racismo ou outras formas de intolerância discriminatória.`
                }
              ]
            },
            {
              item: 'A CORA poderá, a qualquer momento, cancelar a prestação dos Serviços, nas seguintes hipóteses:',
              child: [
                {
                  item: 'a exclusivo critério da CORA, por desinteresse comercial;'
                },
                {
                  item: 'caso Sua Empresa viole quaisquer das disposições destes Termos de Uso ou da legislação e regulamentação infralegal pertinente;'
                },
                {
                  item: 'em caso de má utilização ou uso inadequado dos Serviços por Sua Empresa;'
                },
                {
                  item: 'caso sejam verificadas Transações fora do padrão de uso e Sua Empresa deixe de atender pedido de envio de informações que comprovem a regularidade dessas Transações;'
                },
                {
                  item: 'suspeita de fraude'
                },
                {
                  item: 'não fornecimento dos documentos solicitados;'
                },
                {
                  item: 'constatação de dados incorretos ou inverídicos fornecidos por Sua Empresa;'
                },
                {
                  item: 'encerramento da sua Conta Cora.'
                }
              ]
            },
            {
              item: 'o acessar ou navegar pelas plataformas web da CORA, Sua Empresa, representada por Você, concorda e estará sujeito ao uso de Cookies de empresas terceiras, conforme a Política de Privacidade.'
            },
            {
              item: `A CORA poderá, a qualquer momento e a seu critério, ceder quaisquer de seus direitos e obrigações previstos nestes Termos de Uso a qualquer pessoa,
              mediante simples notificação prévia a Sua Empresa, ficando desde já ressalvado que a cessionária continuará a cumprir com todas as obrigações assumidas pela CORA.`
            },
            {
              item: `A Política de Privacidade da CORA é (i) o instrumento contratual que disciplina a coleta, uso, armazenamento, tratamento e segurança das informações pessoais
              dos usuários, que formaliza nosso respeito por sua privacidade e que está disponível para acesso em: <a href="https://www.cora.com.br/politica-privacidade">www.cora.com.br/politica-privacidade</a> ; e (ii) parte
              integrante e inseparável destes Termos de Uso, de forma que a aceitação destes Termos de Uso implica, necessariamente, a aceitação da Política de Privacidade.
              Por este motivo, Você deverá ler atentamente o referido documento.`
            },
            {
              item: `Qualquer dúvida ou solicitação deverá ser sempre encaminhada aos Canais de Comunicação, que são os canais oficiais para comunicação entre a CORA e Sua
              Empresa, para temas referentes ao uso dos Serviços aqui apresentados: E-mail de atendimento: <a href="mailto=meajuda@cora.com.br">meajuda@cora.com.br</a> (o tempo de resposta para contatos via e-mail é de até 3 (três) dias úteis)
              Whatsapp oficial: (11) 93305-8198 e o chat do aplicativo. O atendimento nos canais acima é disponibilizado 24h por dia, 7 dias por semana.
              Atendimento telefônico: (11) 5039-5715 e 0800 591 2350, todos os dias, das 9h às 17h (horário de Brasília), para pedidos de cancelamento e reclamações.`
            },
            {
              item: `Estes Termos de Uso podem ser alterados a qualquer tempo e, sempre que ocorrer qualquer modificação, Sua Empresa será informada previamente e
              demonstrará que concorda com todas as modificações se continuar a utilizar os Serviços após a alteração dos Termos de Uso. Caso não concorde com os novos
              Termos de Uso, Você poderá rejeitá-los, realizando o cancelamento dos Serviços.`
            },
            {
              item: 'Estes Termos de Uso e a Política de Privacidade vigerão por prazo indeterminado, a partir de sua aceitação.'
            },
            {
              item: 'Estes Termos de Uso e a Política de Privacidade são regidos pelas leis da República Federativa do Brasil.'
            },
            {
              item: `Sua Empresa desde já autoriza a CORA a coletar, tratar e compartilhar os seus dados cadastrais, financeiros e pessoais com as instituições financeiras,
              pertencentes ou não ao grupo da CORA, para obtenção dos financiamentos. A CORA declara que segue os ditames da Lei nº 13.709, de 14 de agosto de 2018
              (Lei Geral de Proteção de Dados Pessoais) no tratamento dos dados do Usuário.`
            }
          ]
        },
      ],
    },
  ]
}
